<template>
  <div
    v-if="relations?.length && routePresence"
    :class="$style.root"
  >
    {{ $t('shared.relatedTo') }}
    <template
      v-for="(relation, index) in relations"
    >
      <layout-animated-underline-component
        :to="{ name: 'editions-slug-index-about', params: { slug: relation?.slug } }"
        :inverted=true
      >
        <template #default>
          {{ relation?.title }}
        </template>
      </layout-animated-underline-component>{{ index < relations.length - 1 ? ', ' : '' }}
    </template>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  data: object
}>()

const relations = computed(() => {
  if (props?.data?.biennialEntry) {
    return [
      props?.data?.biennialEntry
    ]
  } else if (props?.data?.biennialEntries) {
    return props?.data?.biennialEntries
  }
})

const routePresence = getRoutePresence('editions-slug-index-about')
</script>

<style module>
.root {
  composes: font-size-small from global;
  user-select: none;
}
</style>
